import styles from './GlobalSearch.module.scss'

function GlobalSearch() {
  return (
    <>
      <div className={styles.wrapper}>
        <input className={styles.globalSearch} type="text" />
      </div>
    </>
  )
}

export default GlobalSearch
