import { useEffect, useRef } from 'react'
import styles from './Dropdown.module.scss'

interface MenuItem {
  label: string
  href: string
  submenu?: MenuItem[]
}

interface IDropdownMenuProps {
  // ref: React.RefObject<HTMLDivElement>
  dropdownItems: MenuItem[]
  closeDropdown: () => void
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  dropdownItems,
  closeDropdown,
}: IDropdownMenuProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Close dropdown if clicked outside nav or dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown()
      }
    }

    // Add event listener to detect clicks outside nav and dropdown
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  return (
    <div ref={dropdownRef} className={styles.wrapper}>
      <ul className={styles.dropdown}>
        {dropdownItems.map((item, index) => (
          <li key={index}>
            <a href={item.href} className={styles.link}>
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropdownMenu
