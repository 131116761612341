import { useEffect } from 'react'
import { useGetHtmlPostsQuery } from '../redux/features/api/fetchWpPostsSlice'

const SamplePostsPage = () => {
  const { data, error, isLoading } = useGetHtmlPostsQuery({})

  useEffect(() => {
    console.log('RUN...')
    if (data) {
      console.log(data)
    }
    if (error) {
      console.error('Error fetching data:', error)
    }
  }, [data, error])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <div>SamplePostsPage</div>
}

export default SamplePostsPage
