import { SpeedInsights } from '@vercel/speed-insights/react'
import { Header } from 'layout/Header/Header'
import SamplePostsPage from 'pages/SamplePostsPage'

function App() {
  return (
    <div className="App">
      <Header />
      <SamplePostsPage />
      <SpeedInsights />
    </div>
  )
}

export default App
