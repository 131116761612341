import styles from './Header.module.scss'
import codingakhada_logo from 'assets/brand/logo/logo-codingakhada.png'
import navMenu from 'data/layout/header/nav_menu.json'
import HorizontalMenu from './components/HorizontalMenu/HorizontalMenu'
import GlobalSearch from './components/GlobalSearch/GlobalSearch'

export interface IHeaderProps {}

export function Header(props: IHeaderProps) {
  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header}>
        <div className={styles.headerLogo}>
          <img src={codingakhada_logo} alt="" />
        </div>
        <div className={styles.headerNav}>
          <HorizontalMenu menuItems={navMenu} />
        </div>
        <div className={styles.headerSearch}>
          <GlobalSearch />
        </div>
      </header>
    </div>
  )
}
