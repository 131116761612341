import React from 'react'
import { useState } from 'react'
import styles from './HorizontalMenu.module.scss'
import DropdownMenu from '../Dropdown/Dropdown'

interface MenuItem {
  id: number
  label: string
  href: string
  submenu?: MenuItem[]
}

interface MenuProps {
  menuItems: MenuItem[]
}

const HorizontalMenu: React.FC<MenuProps> = ({ menuItems }) => {
  const [openIndex, setOpenIndex] = useState<number>(-1)

  const closeDropdown = () => {
    setOpenIndex(-1)
  }

  return (
    <nav className={styles.nav}>
      <ul className={styles.ul}>
        {menuItems.map((item, index) => (
          <li key={index} className={styles.li}>
            <a
              href={item.href}
              className={styles.a}
              role="button"
              onClick={() => setOpenIndex(index)}
              onMouseEnter={() => setOpenIndex(index)}
            >
              {item.label}
            </a>
            {index === openIndex && item.submenu && (
              <DropdownMenu
                dropdownItems={item.submenu}
                closeDropdown={closeDropdown}
              />
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default HorizontalMenu
