import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const fetchWpPostsApi = createApi({
  reducerPath: 'fetchWpPostsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/posts',
  }),
  endpoints: (builder) => ({
    getHtmlPosts: builder.query({
      query: () => 'html',
    }),
    getCssPosts: builder.query({
      query: () => 'css',
    }),
    getJavaScriptPosts: builder.query({
      query: () => 'javascript',
    }),
    getReactPosts: builder.query({
      query: () => 'react-js',
    }),
    getDsaPosts: builder.query({
      query: () => 'dsa',
    }),
  }),
})

export const {
  useGetHtmlPostsQuery,
  useGetCssPostsQuery,
  useGetJavaScriptPostsQuery,
  useGetReactPostsQuery,
} = fetchWpPostsApi
