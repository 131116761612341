import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { fetchWpPostsApi } from './features/api/fetchWpPostsSlice'

export const store = configureStore({
  reducer: {
    [fetchWpPostsApi.reducerPath]: fetchWpPostsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(fetchWpPostsApi.middleware),
})

setupListeners(store.dispatch)

export default store
